require('./bootstrap');

import $ from 'jquery';

window.$ = window.jQuery = $;

import 'jquery-datetimepicker';

$(document).ready(function () {
    jQuery.datetimepicker.setLocale('de');
    jQuery('.datetimepicker').datetimepicker({
        timepicker: false,
        format: 'Y-m-d'
    });

    $('input[type=radio]').change(function () {
        let input = $('.input-' + $('input[type=radio]:checked').data('optionId'))
        input.fadeIn();
        $('.inputInvisible').not(input).fadeOut()
    })

    $('.answer-check').click(function () {
        if ($(this).prop('checked')) {
            $('.input-' + $(this).data('optionId')).fadeIn();
        } else {
            $('.input-' + $(this).data('optionId')).fadeOut();
        }
    });

    $('#submit-answer').click(function (event) {
        if ($('.answer-check').length && !$(':checkbox:checked').length) {
            event.preventDefault();
            $('#multipleAnswerValidationHint').modal('show');
        }
    });

    $('.showTextInputs').click(function () {
        $.get($(this).data('route'), function (data) {
            let html = '';
            $.each(JSON.parse(data), function (index, value) {
                html += '<tr><td>';
                html += value['text_value'];
                html += '</td><td>';
                html += value['total'];
                html += '</td></tr>';
            });
            $('#modalInputBody').html(html);
            $('#modalInputList').modal('show');
        });
    });

    $('.showNumberInputs').click(function () {
        let count = $(this).data('answers');
        $.get($(this).data('route'), function (data) {
            let html = '<tr><td>' + count + '</td><td>' + data + '</td></tr>';
            $('#modalAverageBody').html(html);
            $('#modalAverage').modal('show');
        });
    });

    let input = $('.input-font')
    if (input.length) {

        input.each(function () {
            $(`div[data-type="${$(this).data('type')}"]`).css({fontSize: $(this).val() + 'px'})
        })

        input.change(function () {
            $(`div[data-type="${$(this).data('type')}"]`).css({fontSize: $(this).val() + 'px'})
        })
    }

    $('input[type="file"]').change(function () {
        let reader = new FileReader()
        reader.readAsDataURL(this.files[0])
        reader.onload = (e) => {
            $(`img[data-image="${$(this).data('type')}"]`).attr('src', e.target.result)
        }
    })

    $('.img-submit').click(function (e) {
        e.preventDefault()
        if ($('#entrance-image')[0].files[0] === undefined) {
            $('.result').html('<p class="text-danger">Bitte vorher ein Bild auswählen!</p>')
        } else {
            $('#entrance-image-form').submit()
        }
    })
});
